.new-ticket-card-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    padding: 20px;
    gap: 10px;
}
.new-ticket-card-container .new-ticket {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin: 5px;
    white-space: nowrap;
    border-radius: 8px;
    color: white;
}
.new-ticket-card-container .new-ticket div {
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 25px;
    margin-right: 10px;
}
