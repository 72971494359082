.display-option-container {
    display: flex;
    position: relative;
    z-index: 9;
}
.card-modal-container {
    display: flex; /* Hidden by default */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed; /* Stay in place */
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
}
.display-option-container .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    position: absolute;
    top: 100%;
    right: 50%;
    margin-right: 10px;
    z-index: 2;
    width: 300px;
    padding: 9px;
    border-radius: 9px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.display-option-container .card-container .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #969dc5b5;
}

.display-option-container .card-container .card-body {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
}

.card-container .card-body .card-body-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px;
}
.card-container .card-body .card-body-item:not(:first-child) {
    margin-top: 2px;
}
.card-container .card-body .card-body-item:not(:last-child) {
    margin-bottom: 2px;
}
.card-container .card-body .card-body-item:hover {
    background-color: #edeff5;
    border-radius: 5px;
}
