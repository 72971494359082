.timing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 5px 0;
}
.timing-container .colored-circle-container {
    margin: 5px;
}
.timing-container .timing-decorator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
}
