/*table{
    width:100%
}
table th{
    text-align: left;
    padding: 0;
    margin: 0;
    min-width: 100px;
    width: 1%;
}
table td {
    display: table-cell;
    text-align: left;
    padding: 0;
    margin: 0;
    min-width: 100px;
    width: 1%;
    vertical-align: inherit;
}

tr.even-row:nth-child(even) {
    background-color: #f5f6f9;
}
tr.odd-row:nth-child(odd) {
    background-color: #ffffff;
}*/

.__table {
    width: 100%;
    th {
        text-align: left;
        padding: 0;
        margin: 0;
        min-width: 100px;
        width: 1%;
    }
    td {
        display: table-cell;
        text-align: left;
        padding: 0;
        margin: 0;
        min-width: 100px;
        width: 1%;
        vertical-align: inherit;
    }

    tr.even-row {
        background-color: #f5f6f9;
    }
    tr.odd-row {
        background-color: #ffffff;
    }
}
