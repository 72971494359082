.sidebar-item {
    color: #4c5690;
    cursor: pointer;
    /* transition: 0.1s ease; */
}
.sidebar-item:hover {
    color: #2a8bab;
    background-color: #f6f6f6;
}
.static .sidebar-item .expand-arrow {
    opacity: 1;
}
.sidebar-item:hover .expand-arrow {
    opacity: 1;
}
.sidebar-container:not(.static) .expand-arrow {
    width: 0;
    opacity: 0;
}

.sidebar-container:hover .expand-arrow {
    width: auto;
    opacity: 1;
}

.expand-arrow {
    overflow: hidden;
    transition: width 0.3s ease-in-out;
}
