.planning-shift-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #edeff5;
    // height: calc(100vh - 180px);
    height: 100%;
}
.planning-shift-container .planning-shift-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 30px;
}
.planning-shift-body {
    // justify-content: space-evenly;
    // margin: 0 auto;
}
.card-item-container {
    display: flex;
    flex-direction: row;
    // max-width: 140px;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 1px 1px 4px 0px rgba(17, 16, 110, 0.1);
    margin-bottom: 10px;
}
.card-item-container .card-item-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 5px;
    min-height: 50px;
    flex-shrink: 0;
    margin: 7px;
    border-radius: 7px;
}
.card-item-container .card-item-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}
.add-card-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    min-height: 48px;
    border-radius: 4px;
    border: 1px solid #2d2aad;
    box-shadow: 1px 1px 4px 0px rgba(17, 16, 110, 0.1);
    margin-bottom: 10px;
}
.auto-replace-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    // min-width: 90%;
    border: 2px solid #2a8bab;
    border-radius: 4px;
    background-image: url("./bg.png");
    background-repeat: repeat;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 5px;
}
