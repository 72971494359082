// @import url("../../../utils/_variables.scss");
.ticket-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
}
.ticket-container .ticket-actions {
    display: flex;
    flex-direction: row;
    margin-left: 7px;
}
.ticket-container .ticket-actions .ticket-edit {
    padding: 3px;
    border-radius: 2px;
    margin-right: 2px;
    cursor: pointer;
}
.ticket-container .ticket-actions .ticket-close {
    padding: 3px;
    border-radius: 2px;
    margin-left: 2px;
    cursor: pointer;
}
