.planning-banner-container {
    display: table-cell;
    width: 100%;
    height: 100%;
    margin: 0;
    background: #f63;
    box-shadow: 1px 1px 4px 0px rgba(255, 102, 51, 0.3);
}
.planning-banner-container .banner-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    max-height: 60px;
}
.banner-content .banner-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.banner-content .banner-right {
    display: flex;
    gap: 10px;
}
.banner-content .banner-right .published-planning {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid white;
}
.banner-content .banner-right .publish-button {
    display: flex;
    padding: 10px 5px 10px 13px;
    border-radius: 4px;
    gap: 3px;
}
.cell-day-parent {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
// .cell-day-parent .cell-day-child {
//     height: 100%;
//     transition: 0.5s ease;
// }
.cell-day-parent .cell-day-child > div {
    display: none;
}
.cell-day-parent .cell-day-child:hover > div {
    display: block;
}
// .cell-day-parent .cell-day-child:not(:last-child) {
//     border-right: 1px solid #edeff5;
// }
// .cell-day-parent .cell-day-child {
// border-bottom: 1px solid #edeff5;
// }
// tbody:hover .cell-day-parent .cell-day-child:nth-child(even):not(:last-child) {
//     border-right: 1px solid #c7cbe2;
// }
.indisponibility-cell {
    font-size: 12px;
    font-weight: 500;
    background-color: #c7d5ff;
    // color: #2a8bab;
    color: #4c5690;
    border: 1px solid #4c5690;
    // background-image: url("./bg.png");
    border-radius: 5px;
    padding: 5px;
    display: flex;
    gap: 5px;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}
.indisponibility-cell .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.indisponibility-cell .icon svg {
    // stroke-width: 0.1;
    display: block;
}
.indisponibility-cell .text {
    // background-color: #fb3640;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
