.empty-cell-container {
    font-size: 12px;
    font-weight: 500;
    background-color: #e7f3f7;
    color: #2a8bab;
    background-image: url("./bg.png") !important;
    border-radius: 5px;
    /* padding: 5px; */
    display: flex;
    gap: 5px;
}
