/*============  Nunito Sans   ============*/
@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans/NunitoSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans/NunitoSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans/NunitoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/*============  DM Sans   ============*/
@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans/DMSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans/DMSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans/DMSans-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

:root {
  /*
  * Fonts
  */
  --base-font: "Nunito Sans" ,sans-serif;
  --second-font: "DM Sans", sans-serif;
  /*
  * Colors
    t stands for tail     +       v stands for violet
  */
  --t-0: #CFE0E6;
  --t-100: #A6D3E2;
  --t-200: #7EC3DB;
  --t-300: #5BB3D0;
  --t-400: #3FA0C1;
  --t-500: #2A8BAB;
  --t-600: #1A7290;
  --t-700: #0F556D;
  --t-800: #083544;
  --t-900: #03141A;
  --v-0: #D1D1E8;
  --v-100: #A8A7E4;
  --v-200: #817FDD;
  --v-300: #5E5CD2;
  --v-400: #4240C3;
  --v-500: #2D2AAD;
  --v-600: #1D1B91;
  --v-700: #11106E;
  --v-800: #090844;
  --v-900: #03031A;
  --primary: #646BD9;
  --secondary: #726DA8;
  --success: #4FAB2F;
  --success-light: #D5FFC7;
  --warn: #F2BE03;
  --warn-light: #FFF3C8;
  --error: #D96464;
  --error-light: #FFC8C8;
  --info: #418AEC;
  --info-light: #C8CBFF;
  --testing: #961a1a;
}
