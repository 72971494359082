.vacation-types-container {
  max-height: 200px;
  overflow-y: scroll;
}

.vacation-types-container .multi-planning-tickets {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

.vacation-politic-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.vacation-politic-container .politic-box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.politic-box-container .left {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 3px;
}

.politic-box-container .right {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 7px;
}

.new-conge-rule-container {
  margin: 15px;
}

.new-conge-rule-container p {
  color: #2a346d;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 12px;
  gap: 5px;
}

.employees-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.employees-section h1 {
  margin-left: 15px;
  margin-bottom: 7px;
}

.new-dept-search-bar {
  display: flex;
  flex-direction: row;
  background-color: #edeff5;
}

.new-dept-list-of-employees {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  height: 60vh;
}

.new-dept-list-of-employees .employee {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edeff5;
  margin: 0 17px 0 19px;
}

.new-dept-list-of-employees .employee:last-child {
  border-bottom: 0px solid #edeff5;
}

.new-dept-list-of-employees .employee .information {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 0;
}

.new-dept-list-of-employees .employee .information img {
  margin-right: 15px;
}
