.startup-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #f7f8fa;
}
.startup-container .startup-topbar-container{
    margin: 0 50px;
    margin-top: 10px;
}
.startup-container .startup-body-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 15%;
}
.startup-container .startup-body-container .startup-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}
.startup-container .startup-body-container .startup-header .progressSteps-container{
    width: 40%;
    margin-right: 10%;
}
.startup-body-container .startup-card-container{
    margin: 17px 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
}
.startup-body-container .startup-card-container .footer-card-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 40px;
    border-top: 1px solid gainsboro ;
}
.startup-body-container .startup-card-container .footer-card-container > div{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.startup-card-container .footer-card-container > div .button-style{
    margin: 0 5px;
}