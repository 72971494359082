.new-ticket-container{
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
background-color: #EDEFF5;
padding: 7px;
border-radius: 7px;
cursor: pointer;
}
.new-ticket-container .new-ticket-icon{
    margin-right: 4px;
}

.new-ticket-container .new-ticket-text{
color:#4C5690;
font-weight: bold;
}

