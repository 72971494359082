.authentification-template-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("../../../utils/img/background.png");
    background-size: cover;
    background-position: center;
}
.authentification-template-container .auth-header-container > img {
    margin: auto;
}
// @media (max-height: 800px) {

//     .authentification-title{
//         font-size: 30px !important;
//     }
//     .authentification-picture{
//         width: 200px !important;
//         height: 200px !important;
//     }
//     .login-title{
//         font-size: 20px !important;
//     }
//     .login-subtitle{
//         font-size: 15px !important;
//     }
//     //for both inputs email & password & checkbox
//     .LoginMolecule-container .caption-container,
//     .LoginMolecule-container .checkbox-container{
//         margin-top: 8px!important;
//     }
//     //for button se connecter
//     .LoginMolecule-container .Button{
//         margin-top: 15px!important;
//         margin-bottom: 15px!important;
//     }
// }
@media (max-height: 900px) {
    .auth-header-lang-container > div {
        top: 4%; /* Reduce the font size for smaller screens */
    }
    .auth-footer img {
        width: 7%;
    }
    .auth-footer h1 {
        font-size: 100%; /* Reduce the font size for smaller screens */
    }
}
