.level-progress-bar-container {
    display: flex;
    flex-direction: row;
    gap: 2px;
}
.level-progress-bar-container .element-container {
    background-color: aquamarine;
    padding: 5px 20px;
}
.level-progress-bar-container .element-container:first-child {
    border-radius: 15px 0 0 15px;
}
.level-progress-bar-container .element-container:last-child {
    border-radius: 0 15px 15px 0;
}
