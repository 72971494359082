.tab-navigation-container {
    background-color: white;
    border-radius: 20px;
}
.tab-navigation-container .header-banner ul {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border-bottom: 2px solid #edeff5;
    padding-bottom: 0;
}

.tab-navigation-container .header-banner ul li {
    display: flex;
    align-items: center;
    margin-bottom: -2px;
    margin-right: 4%;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
    color: #5bb3d0;
}

.settings-container .settings-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
    border-top: 1px solid #edeff5;
    border-radius: 0 0 20px 20px;
}
.settings-container .settings-footer button {
    margin-right: 10px;
}
/********* Report *********/
.report-tab-navigation-container {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.report-tab-navigation-container .header-banner {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edeff5;
    height: 62px;
}
.report-tab-navigation-container .header-banner ul.tab-switcher {
    display: flex;
    padding: 15px;
    padding-bottom: 0;
}
.report-tab-navigation-container .header-banner ul.tab-switcher li {
    margin-right: 40px;
}
.report-tab-navigation-container .header-banner ul.side-tabs {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    padding-bottom: 0;
    margin-left: auto;
    width: 440px;
}
.report-tab-navigation-container .header-banner ul li {
    margin-bottom: -2px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 15px;
    font-weight: bold;
    cursor: pointer;
    color: #5bb3d0;
}
