.planning-cell-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: auto;
    // height: inherit;
    width: 100%;
    // overflow: hidden;
    // height: 100%;
    // border-radius: 12px;
    // padding: 30px;
}
.planning-cell-container .indisponibility-cell {
    font-size: 12px;
    font-weight: 500;
    background-color: #c7d5ff;
    // color: #2a8bab;
    color: #4c5690;
    border: 1px solid #4c5690;
    // background-image: url("./bg.png");
    border-radius: 5px;
    padding: 5px;
    display: flex;
    gap: 5px;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}
.planning-cell-container .indisponibility-cell .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.planning-cell-container .indisponibility-cell .icon svg {
    // stroke-width: 0.1;
    display: block;
}
.planning-cell-container .indisponibility-cell .text {
    // background-color: #fb3640;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.empty-cell-container {
    height: 100%;
    width: 100%;
    // height: 100%;
    // margin: 2px;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    opacity: 0;
}
.planning-cell-container:hover .empty-cell-container {
    visibility: visible;
    opacity: 1;
}
.cell-day-child:hover .empty-cell-container {
    visibility: visible;
    opacity: 1;
}
.planning-cell-container .planning-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
    background-color: aquamarine;
}

.planning-cell-container .rest-planning {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.alert-box {
    position: relative;
}
.planning-cell-container .rest-planning .alert-label {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    height: 22px;
    // background-color: rgba(251, 54, 64, 0.1);
    background-color: #ffebec;
    margin: 2px;
    padding: 3px;
    border-radius: 5px;
}

.planning-cell-container .rest-planning .alert-label .alert-showing {
    position: absolute;
    top: calc(100% + 3px);
    right: 0;
    background-color: rgb(255, 244, 244);
    padding: 5px;
    display: none;
    border: 1px solid #fb3640;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 250px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 20;
}
.planning-cell-container .rest-planning .alert-label:hover .alert-showing {
    display: flex;
}

.shift-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // max-width: 192px;
    // width: 100%;
    // height: 100%;
    min-height: 1%;
    border-radius: 12px;
}
.shift-container .shift-hours-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.shift-container .shift-hours-container .hours-adjustment {
}

.shift-hours-container .hours-adjustment .hours-adjustment-text {
    font-size: 12px;
    font-weight: 900;
}

.shift-container .shift-role-container {
}
.shift-container .shift-role-container:only-child {
    justify-content: flex-start;
}
.shift-container .shift-role-container label {
    font-size: 14px;
}
.details-container {
    // display: none;
    display: flex;
    opacity: 0;
    transition: 0.2s ease-in-out;
}
.summary-container:hover .details-container {
    // display: flex;
    opacity: 1;
}
.cell-comment .comment-text {
    display: none;
}
.cell-comment:hover .comment-text {
    display: block;
}
@media (min-width: 769px) and (max-width: 1200px) {
    .shift-container .shift-hours-container .hours-adjustment {
        margin-right: 0;
    }
    .shift-container {
        width: 100px;
    }
    .shift-hours-container .hours-adjustment {
        justify-content: center !important;
        flex-wrap: wrap;
    }
    .shift-container .shift-role-container label {
        font-size: 12px;
    }
    .shift-hours-container .hours-adjustment .hours-adjustment-text {
        // font-size: 11px;
        // font-weight: 500;
        // margin: 1px;
    }
}
