.employee-profile-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    // background-color: #bdc3c7;
}

// TabNavigationV2 Component styling
.employee-profile-container .tab-navigation-container {
    background-color: transparent;
    border-radius: 0;
}
.employee-profile-container .tab-navigation-container .header-banner {
    // background-color: gray;
}
.employee-profile-container .tab-navigation-container .header-banner ul {
    padding: unset;
    border-bottom: unset;
    gap: 48px;
    margin-left: 50px;
}
.employee-profile-container .tab-navigation-container .header-banner ul li {
    all: unset;
}

.employee-profile-container .tab-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 15px;
    animation: translateAnimation 0.7s ease-in-out;
}
@keyframes translateAnimation {
    from {
        transform: translateY(-20px); /* Initial translation value */
        opacity: 0; /* Initial opacity if needed */
    }
    to {
        transform: translateY(0); /* Final translation value */
        opacity: 1; /* Final opacity if needed */
    }
}
