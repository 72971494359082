.employee-info-popup-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1000px;
    padding-left: 40px;
    gap: 5px;
}
.employee-info-popup-container .employee-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.employee-info-popup-container .employee-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: auto;
}
.employee-info-popup-container .employee-position {
    display: flex;
    // height: 30px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 34px;
    border: 1px solid #c7cbe2;
    background: #fff;
}
.employee-info-popup-container .employee-position .dot {
    display: inline-block;
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.workhours-popup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.work-hours-overview-card {
    padding: 10px 45px 20px 51px;
    min-height: 100px;
}
.work-hours-overview-card table {
    width: 100%;
}
.work-hours-overview-card table .overview-card-header {
    text-align: left;
}
.work-hours-overview-card table .overview-card-header th {
    background-color: #edeff5;
}
.work-hours-overview-card table .overview-card-header th:not(:first-child) {
    text-align: center;
}
.work-hours-overview-card table .overview-card-header th:first-child {
    border-radius: 8px 0 0 0;
}
.work-hours-overview-card table .overview-card-header th:last-child {
    border-radius: 0 8px 0 0;
}
.work-hours-overview-card table .overview-card-body {
    border-bottom: 1px solid #edeff563;
    height: 50px;
}
.work-hours-overview-card table tr td,
.work-hours-overview-card table tr th {
    padding: 5px 0px 5px 20px;
    font-size: 14px;
}
.work-hours-overview-card table .overview-card-header,
.work-hours-overview-card table .overview-card-body,
.work-hours-overview-card table .overview-card-footer {
    color: #2a346d;
}
.work-hours-overview-card table .overview-card-footer {
    font-weight: bold;
}
.work-hours-overview-card table tr td:not(:first-child) {
    text-align: center;
}
.work-hours-overview-card table tr td > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.work-hours-overview-card table .overview-card-footer td {
    background-color: #edeff5;
}
.work-hours-overview-card table .overview-card-footer td:first-child {
    border-radius: 0 0 0 8px;
}
.work-hours-overview-card table .overview-card-footer td:last-child {
    border-radius: 0 0 8px 0;
}
.work-hours-overview-card table .overview-card-body:nth-child(odd) {
    background-color: #edeff563;
    height: 50px;
}
.work-hours-overview-card .timing-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.workhours-popup-container .date-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
}
.workhours-popup-container .date-info .date-range > * {
    display: block;
}
.workhours-popup-container .date-info .date-range {
    display: flex;
    justify-content: space-between;
}
