.progress-steps-container{
    width: 100%;
    margin: 0 auto;
    padding: 50px 16px;
}

.step-container{
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: 0 50px;
    margin-top: 20px; //TOREMOVE
}
.step-wrapper-before {
    content: "";
    position: absolute;
    background: #6F78AB;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}
.step-wrapper{
    position: relative;
    z-index: 1;
}
.step-wrapper-after{
    content: '';
    position: absolute;
    background: #2A8BAB;
    height: 4px;
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
}
.steps-stepName-container{
    position: absolute;
    top: 20px;
    width: max-content;
    left: 50%;
    transform: translate(-50%, -50%);
}
.steps-stepName-container .step-name{
    font-size: 10px;
    color: #6F78AB;
    @media (max-width: 600px) {
        font-size: 8px;
    }
}
.step-style{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: 0.4s ease;
    margin:40px 0 ;
}
.check-mark{
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 19px;
    font-weight: 600;
    color:white;
    background-color: #2A8BAB;
    -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-46deg);
}
.step-count{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    font-size: 19px;
    color: #f3e7f3;
    background-color: red;
    @media (max-width: 600px) {
        font-size: 16px;
    }
}
.steps-label-container{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.step-label{
    font-size: 11px;
    color: #4a154b;
    font-weight: bold;
    @media (max-width: 600px) {
        font-size: 8px;
    }
}
.button-container{
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
    margin-top: 100px;
}
.button-style{
    border-radius: 4px;
    border: 0;
    background: #4a154b;
    color: #ffffff;
    cursor: pointer;
    padding: 8px;
    width: 90px;
}
.button-style:active {
    transform: scale(0.98);
}
.button-style:disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
}