.radio-group .radio{
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    color:#fff;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    margin-left: 20px;
}
// .radio-group .radio + .radio{
// }
.radio-group .radio input[type="radio"]{
    display: none;
}
.radio-group .radio span{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #A7ADD0;
    display: block;
    position: absolute;
    left: 0;
    top: 15%;
}
.radio-group .radio span:after{
    content:"";
    height: 6px;
    width: 6px;
    background-color: #3FA0C1;
    display: block;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%) scale(0);
    border-radius: 50%;
    transition:300ms ease-in-out 0s;
}
.radio-group .radio input[type="radio"]:checked ~ span{
    border: 2px solid #3FA0C1;
}
.radio-group .radio input[type="radio"]:checked ~ span:after{
    transform:translate(-50%,-50%) scale(1);

}
