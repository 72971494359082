.delay-right-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
.delay-right-menu input[name="startDate"],
.delay-right-menu input[name="endDate"] {
    border: 1px solid #c7cbe2;
    border-radius: 8px;
    font-size: 12px;
    font-weight: normal;
    color: #4c5690;
    padding: 0 10px;
    width: 132px;
    height: 37px;
}
.delay-table-container {
    overflow-x: auto;
    max-height: calc(100vh - 250px);
}
.Delay-table-alternative {
    max-height: calc(100vh - 250px);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
