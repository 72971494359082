.validated-day {
    background-color: #00a42e0d;
    color: #00a42e;
}
.locked-day {
    background-color: #f43f5e0d;
    color: #fb3640;
}
.today {
    background: #4240c31a;
    color: #4240c3;
    border-radius: 50%;
}
