.popupHistoryContainer {
    width: 90%;
    margin: 0 5%;
    margin-bottom: 50px;
    // margin-top: 20px;
}
.popupHistoryContainer .historyHeader {
    text-align: left;
    position: sticky;
    background-color: #edeff5;
    border-radius: 5px 5px 0 0;
}
.popupHistoryContainer .historyHeader th {
    background-color: #edeff5;
}
.popupHistoryContainer .historyHeader th:not(:first-child) {
    text-align: center;
}
.popupHistoryContainer .historyHeader th:first-child {
    border-radius: 8px 0 0 0;
}
.popupHistoryContainer .historyHeader th:last-child {
    border-radius: 0 8px 0 0;
}

.popupHistoryContainer .historyBody {
    border-bottom: 1px solid #edeff563;
}

.popupHistoryContainer .historyBody tr td,
.popupHistoryContainer .historyBody tr th {
    padding: 5px 20px 5px 20px;
    font-size: 14px;
}

.popupHistoryContainer .historyHeader,
.popupHistoryContainer .historyBody {
    color: #2a346d;
}
.popupHistoryContainer .historyBody:nth-child(odd) {
    background-color: #edeff563;
}

.popupHistoryContainer .historyBody tr td:not(:first-child) {
    text-align: center;
}
