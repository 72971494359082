.lang-container{
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 150px;
    padding:7px 10px;
    border-radius: 5px;
}
.lang-container h1{
    text-transform: capitalize;
    margin-left: 6px;
    margin-right: 6px;
}
.lang-list{
    padding:7px 10px;
    border: 2px solid #C7CBE2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.lang-item {
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-bottom: 5px;
    cursor: pointer;
}
.lang-item-absolute{
}
