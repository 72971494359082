.general-info-container {
    background-color: white;
    height: 100%;
    border-radius: 12px;
    box-shadow: 1px 1px 4px 0px rgba(17, 16, 110, 0.1);
    padding: 50px;
}
.general-info-container ::placeholder {
    color: #4c5690;
}
.general-info-container .general-info-inputs-container {
    display: flex;
    flex-wrap: wrap;
}
.general-info-container .change-photo-container {
}
