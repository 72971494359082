:root {
    --scrollbar: #888888;
}

@import url("./utils/_variables.css");

body {
    background: #fafafa;
    font-family: "DM Sans";
    font-family: "DM Sans", sans-serif;
}

._shadow {
    box-shadow: 1px 1px 4px rgba(17, 16, 110, 0.1);
}

._noWordBreak {
    word-break: keep-all !important;
}

/* https://scottspence.com/posts/change-scrollbar-color-tailwind-css */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar);
    border-radius: 14px;
    border: 3px solid var(--scrollbar);
}
* {
    padding: 0;
    margin: 0;
}
