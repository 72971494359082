.notifications-page-container {
    width: 650px;
    margin: 20px auto;
}
.notifications-page-container .head {
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.notifications-page-container .content {
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    position: relative;
}
.notifications-page-container .content > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.notifications-page-container .content .sub-head {
    margin: 5px 0;
}

/*notitfications item*/
.notifications-page-container .content .notification-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    padding: 10px;
    border-radius: 8px;
}

.notifications-page-container .content .notification-item .notification-icon {
    padding: 10px;
    border-radius: 50%;
    background-color: rgb(253, 197, 74);
}
.notifications-page-container .notification-item .notification-icon {
}
.notifications-page-container .notification-item .notification-text {
    width: 100%;
}
.notifications-page-container .notification-item .notification-text b {
    color: #2a8bab;
}
.notifications-page-container .notification-item .notification-text .title {
}
.notifications-page-container
    .notification-item
    .notification-text
    .description {
}
.notifications-page-container .notification-item .notification-details {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
}
.notifications-page-container .notification-item .notification-details > div {
    display: block;
}
.notifications-page-container
    .notification-item
    .notification-details
    .clicked-notif {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.notifications-page-container
    .notification-item
    .notification-details
    .unclicked-notif {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #2a8bab;
}
