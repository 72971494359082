.pointeuse-table-head {
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 20;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
}
.pointeuse-table-head tr th {
    height: 80px;
    outline: 1px solid #edeff5;
    font-size: 15px;
    color: #4c5690;
}
.pointeuse-table-head tr th:not(:last-child) {
    border-right: 1px solid #edeff5;
}
.pointeuse-table-head .title {
    height: 100%;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.pointeuse-table-head .title:not(:last-child) {
}

.pointeuse-table-head .title .main-title {
    border-bottom: 1px solid #edeff5;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 60%;
    font-weight: bold;
}
.pointeuse-table-head .title .sub-titles {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    width: 100%;
    font-weight: 300;
    font-size: 12px;
}
.pointeuse-table-head .title .sub-titles > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pointeuse-table-head .title .sub-titles > div:not(:last-child) {
    border-right: 1px solid #edeff5;
}
.pointeuse-table-head .photo-icon:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border: 10px solid;
    top: 16px;
    left: 0;
    border-color: transparent transparent #101844 transparent;
    display: none;
}
.pointeuse-table-head .photo-icon:hover:after {
    display: block;
}
.pointeuse-table-head .photo-icon .alert-message {
    display: none;
}
.pointeuse-table-head .photo-icon:hover .alert-message {
    display: block;
}
