.planning-employee-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    padding: 8px;
    background-color: white;
}
.planning-employee-cell .employee-photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    min-width: 50px;
    min-height: 50px;
}
.planning-employee-cell .employee-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.employee-data-container .employee-position {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #c7cbe2;
    padding: 2px 10px;
    gap: 5px;
}
.employee-data-container .employee-position .dot {
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.employee-data-container .employee-hours {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-top: 7px;
    gap: 10px;
}
.employee-data-container .employee-hours .employee-time {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: start;
    padding: 1px 4px;
    border-radius: 4px;
    background: #edeff5;
    height: fit-content;
}

.absence-icon-cell {
    background-color: #bcdde7;
}
.absence-icon-cell:hover {
    background-color: #7ec3db;
}
.absence-icon-cell > div {
    display: none;
}

.absence-icon-cell:hover > div {
    display: block;
}
