$len: 300px;
$time: 2000ms;

body {
    //background: #A6D3E2;
}
.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
        // position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

#infinity-outline {
    fill: transparent;
    stroke-width: 3;
    stroke: #083544;
    stroke-dasharray:
        $len * 0.01,
        $len;
    stroke-dashoffset: 0;
    animation: anim $time linear infinite;
}
#infinity-bg {
    fill: transparent;
    stroke-width: 3;
    stroke: #1a7290;
    opacity: 0.2;
}
@keyframes anim {
    12.5% {
        stroke-dasharray:
            $len * 0.14,
            $len;
        stroke-dashoffset: -$len * 0.11;
    }
    43.75% {
        stroke-dasharray:
            $len * 0.35,
            $len;
        stroke-dashoffset: -$len * 0.35;
    }
    100% {
        stroke-dasharray:
            $len * 0.01,
            $len;
        stroke-dashoffset: -$len * 0.99;
    }
}
