.productivity-container{
    display: flex;
    position: relative;
    flex-direction: row;
    overflow-x: hidden;
}
.productivity-container .payroll-stats-container{
    display: flex;
    flex-direction: column;
    width: 35%;
}
.productivity-container .productivity-data-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.productivity-container .productivity-data-container .productivity-data-cards{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 20px;
}

.week-ratio-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2d2aad1a;
    padding: 15px;
    border-radius: 8px;
}
.week-ratio-container .clickable-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    border:1px solid #C7CBE2;
    border-radius: 4px;
}
.info-card-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    background-color: #2D2AAD;
    padding: 15px;
    border-radius: 8px;
    width: 135px;
    height: 100px;
}

.week-dates-container{
    display: flex;
    flex-direction: column;
}
.week-dates-container .week-selection{
    display: flex;
    flex-direction: row;
    align-items: center;
}