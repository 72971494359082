.topbar-container {
    // position: fixed;
    width: 100%;
    height: 70px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    // z-index: 999999999999999999999999999999999999;
    // position: fixed;
    // width: calc(100vw - 240px);

    .search-section {
        display: flex;
        flex-direction: row;
        //justify-content: space-evenly;
        align-items: center;
        // @media screen and (max-width: 768) {
        //     display: none;
        // }
    }
    .profile-section {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        .image-container {
            margin-right: 8px;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            overflow: hidden;
        }
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    .profile-section svg {
        cursor: pointer;
    }
}

.notifications-wrapper {
    position: relative;
}

.notifications-wrapper .notifications-icon {
    cursor: pointer;
    position: relative;
    z-index: 998;
}
.notifications-wrapper .notifications-icon span {
    position: absolute;
    top: -5px;
    right: 0;
    transform: translateX(10%);
    background-color: #2a8bab;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 8px;
    color: white;
    font-size: 12px;
    border: 2px solid white;
}
