.error-404-title {
    font-size: 64px;
    font-family: "DM Sans", sans-serif;
    font-weight: 900;
    color: #2A8BAB;
}
.error-404-button {
    background: #2A8BAB;
    color: #fff;
    font-size: 22px;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
}
