@import "../../../utils//variables";
.checkbox-container {
    display: flex;
    align-items: center;
    position: relative
}

.tick {
    width: 80px;
    height: 20px;
}

.checkbox-container label {
    //this X
    cursor: pointer;
    display: flex;
    align-items: center;
}

.checkbox-container input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.checkbox-container label .checkbox-icon {
    //this X
    // content: "";
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    margin-right: 3px;
    border: 0.063em solid var(--t-600);
    padding: 2px;
}

.checkbox-container label.rounded .checkbox-icon {
    border-radius: 9999px;
}

.checkbox-container label:hover::before,
.checkbox-container input[type="checkbox"]:hover+label .checkbox-icon {
    //this
    background-color: var(--t-400);
}

.checkbox-container input[type="checkbox"]:disabled+label,
.checkbox-container input[type="checkbox"]:disabled {
    //this
    color: #aaa;
    cursor: default;
}

.checkbox-container input[type="checkbox"]:checked+label .checkbox-icon {
    //this
    content: url(tick.svg);
    background-color: var(--t-600);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.checkbox-container input[type="checkbox"]:disabled+label .checkbox-icon {
    //this
    background-color: #ccc;
    border-color: #999;
}