.thead-style{
    background-color: aqua;
}
.tbody-style{
    background-color: rebeccapurple;
}
.payroll-performance-card{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eee;
    padding: 20px;
}
.payroll-performance-card .card-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}
.payroll-performance-card .card-header .card-title{
    display: flex;
    flex-direction: column;
}
.payroll-performance-card .card-header .card-title .money-title{
    display: flex;
    flex-direction: row;
}

.payroll-performance-card .card-header .card-options-selection{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-right: 5px;
}
.payroll-performance-card .card-header .card-options-selection .card-toggle-button{
    display: flex;
    flex-direction: row;
    border: 1px solid #eee;
    border-radius: 4px;
}
.payroll-performance-card .card-body{
    display: flex;
    flex-direction: row;
    width: 100%;
}

/* Styling the Char*/
// Styling of ChartLine

#tooltip-body{
display: flex;
flex-direction: column;
padding:7px;
background-color:#101844 ;
border-radius: 7px;
color:white;
}

#tooltip-body .tooltip-header{
display: flex;
flex-direction: row;
align-items: center;
}

#tooltip-body .tooltip-header span:first-child{
font-size: small;
font-weight: bold;
}

#tooltip-body .tooltip-header #dot-tooltip{
display: flex;
width: 10px;
height: 10px;
margin-left: 15px;
background-color: #A8A7E4;
border-radius: 15px;
}

#tooltip-body .tooltip-content{
display: flex;
flex-direction: row;
font-size: 0.8em;
font-weight: bolder;
}

#tooltip-body .tooltip-content span{
display: flex;
flex-direction: row;
justify-content: start;
font-size: 0.6em;
font-weight: bold;
}
// Styling of PayrollPerformanceCard Component.chart-container{ width: 700px; background-color: white;}.chart-header{ display: flex; flex-direction: row; justify-content: space-evenly; align-items: center; padding: 10px;}.turnover-container{ display: flex; flex-direction: column; align-items: center; background-color: white; padding:7px;}.turnover-value{ display: flex; flex-direction: row; font-weight: bold;}
.data-selection{ 
    max-width: 250px;
}
.data-type-toggle{ 
    display: flex; 
    flex-direction: row;
}
.data-type-toggle span{ 
    display: flex; 
    flex-direction: row; 
    background-color: white; 
    border: 1px solid #C7CBE2; 
    border-radius: 4px;
}
.chart-body{}