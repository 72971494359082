.notifications-wrapper .notifications-card {
    position: absolute;
    z-index: 998;
    background-color: white;
    top: 40px;
    right: 0;
    border-radius: 8px;
    overflow: hidden;
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
/* Media query for smaller viewports */
@media (max-width: 525px) {
    .notifications-wrapper .notifications-card {
        width: 300px;
    }
}
@media (max-width: 300px) {
    .notifications-wrapper .notifications-card {
        width: 260px;
    }
}
.notifications-wrapper .notifications-card .head {
    background: linear-gradient(95deg, #2a8bab 0%, #2d2aad 100%);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notifications-wrapper .notifications-card .content {
    padding: 15px 10px 15px 15px;
    max-height: 400px;
    overflow-y: auto;
    position: relative;
}
.notifications-wrapper .notifications-card .content > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

/* .notifications-wrapper .notifications-card .content .old {
    padding-top: 10px;
} */

.notifications-wrapper .notifications-card .content .notification-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
}
.notifications-wrapper
    .notifications-card
    .content
    .notification-item
    .notification-icon {
    padding: 10px;
    border-radius: 50%;
    background-color: rgb(253, 197, 74);
}
.notifications-wrapper .notification-item .notification-icon {
}
.notifications-wrapper .notification-item .notification-text {
    width: 100%;
}
.notifications-wrapper .notification-item .notification-text b {
    color: #2a8bab;
}
.notifications-wrapper .notification-item .notification-text .title {
}
.notifications-wrapper .notification-item .notification-text .description {
}
.notifications-wrapper .notification-item .notification-details {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
}
.notifications-wrapper .notification-item .notification-details > div {
    display: block;
}
.notifications-wrapper .notification-item .notification-details .clicked-notif {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.notifications-wrapper
    .notification-item
    .notification-details
    .unclicked-notif {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2a8bab;
}

.notifications-card-foot {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.1);
}
.notifications-card-foot a {
    color: #2d2aad;
    color: #2a8bab;
    color: #2b70ac;
}
