.upload-file-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    height: auto !important;
    overflow: hidden;
}
.upload-file-container .upload-text {
    text-align: center;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 7px;
    min-width: 150px;
}
.upload-file-container .selected-file-msg {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #1a7290;
    min-width: 150px;
}
.upload-file-container input[type="file"] {
    display: none;
}
