.table-week-header-container{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    transition: all 0.4s ease-in;
    height: 50px;
    padding: 7px;
}
.cell-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.tag-corner {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
}
.offScreen {
    position:absolute;
    top: -9999px;
    left: -9999px;
}