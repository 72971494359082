.authentification-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 500px;
    min-width: 350px;
    width: 60%;
    padding: 10px;
}
.left-auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 20px;
    font-size: 36px;
}
.right-auth {
    width: 50%;
}
// Login Molecule
.LoginMolecule-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5px 27px 5px 27px;
}
// Reset Password Molecule
.reset-password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 5px 27px 5px 27px;
    align-items: center;
}
.password-requirements {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
}
.each-requirement {
    display: flex;
    margin-bottom: 2px;
    align-items: center;
    gap: 5px;
}

//change password Molecule
.changePassword-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 15px 27px 5px 40px;
    align-items: center;
}
// Forget Password Molecule
.forget-password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 5px 27px 5px 27px;
    align-items: center;
}
// Forget Password Molecule
.check-email-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 10px 27px 5px 27px;
    align-items: center;
}
