.employee-profile-container .employee-profile-header-container {
    background-color: #ecf0f1;
}

.employee-profile-header-container .go-back-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    font-size: 14px;
    color: #2a8bab;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
}
