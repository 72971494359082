.settings-container {
    display: flex;
    flex-direction: column;
}

.settings-container .settings-title {
    margin: 0px 1.25rem 2rem;
}

.settings-container .setting-body {
    background-color: white;
    border-radius: 20px;
}

.no-access {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 5;
    opacity: 1;
}

.no-access .no-access-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.schedule-container .multi-planning-tickets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.schedule-container .multi-planning-tickets > * {
    margin-right: 7px;
    margin-bottom: 7px;
}

.integration-container .caisses-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 15px;
}

.integration-container .caisses-container > * {
    margin-right: 28px;
}

.vacation-container,
.integration-container,
.schedule-container,
.branch-container,
.time-tracker-container,
.administrative-container {
    position: relative;
    // height: calc(100vh - 500px);
    height: fit-content;
}

.comming-soon-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    z-index: 2;
    opacity: 1;
}

.comming-soon-container .comming-soon-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.administrative-container .information-card-dpae-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.administrative-container .information-card-dpae-box .elementContainer {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    margin: 5px;
    gap: 17px;
}

.administrative-container .information-card-dpae-box .elementContainer .title {
    width: 20%;
    font-weight: bold !important;
}

.administrative-container .information-card-dpae-box .elementContainer .value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    margin-right: 5%;
    padding: 5px;
    border-radius: 5px;
    background-color: #f4fcff;
    color: #2a8bab !important;
}

.administrative-container .tags-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.administrative-container .list-of-templates {
    max-height: 500px;
    overflow: auto;
    margin-bottom: 10px;
}

.collective-agreement-container {
    display: flex;
    flex-direction: column;
}

.collective-agreement-container .collective-agreement-list-container {
    display: flex;
    flex-direction: column;
    max-height: 500vh;
}

$selectedCA: ".collective-agreement-container .selected-collective-agreement-container";
#{$selectedCA} {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 20px;
    gap: 15px;
}

#{$selectedCA} .collective-agreement-item-container {
    display: flex;
    align-items: center;
}

#{$selectedCA} .collective-agreement-item-container .item-header {
    display: flex;
    flex-direction: column;
    width: 90%;
}
.settings-container .settings-footer {
    position: sticky;
    bottom: 0;
    padding: 10px !important;
}
