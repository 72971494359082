.Alert{
    display: flex;
    flex-direction: column;
}

.header-container{
    display: flex;
    top:0px;
    left:1%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 40px;
    width: 110px;
    // background-color: #FB3640;
    border-radius: 7px;
    color:white;
}
.header-icon{
    width:25px;
    height: 25px;
    margin-right: 3px;
    margin-left: 3px;
}
.header-title{
    font-size: 15px;
    font-weight: bolder;
    text-transform: uppercase;
    margin-right: 3px;
    margin-left: 3px;
}

.alert-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 5px;
}
.alert-icon{
    margin-left:15px;
    margin-right: 15px;
    margin-top: 15px;
    align-self: flex-start;
}
.alert-closable{
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    align-self: flex-start;
}
.alert-body{
    margin-top: 10px;
    margin-bottom: 15px;
    align-items: flex-start;
    text-align: left;
    width: 90%;
}