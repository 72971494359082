.colored-circle-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px;
}
.colored-circle-container .radio {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.colored-circle-container .radio input[type="radio"] {
  display: none;
}

.colored-circle-container .radio span.isNotChecked {
    display: block;
    transition: 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28) 0s;
}

.colored-circle-container .radio span.isChecked {
    margin:1.5px;
} 
