.pointeuse-table-body {
    background-color: white;
    position: relative !important;
}
.pointeuse-table-body tr {
    border-bottom: 1px solid #edeff5;
}
.pointeuse-table-body tr td:not(:last-child) {
    border-right: 1px solid #edeff5;
}
.pointeuse-table-body tr td > div {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="time"] {
    /* Remove any padding */
    padding: 0;
}

/* Target the time input's shadow DOM */
input[type="time"]::-webkit-calendar-picker-indicator {
    /* Adjust the size of the icon */
    width: 15px; /* Adjust the width as needed */
    height: 15px; /* Adjust the height as needed */
    /* Reduce the padding around the icon */
    padding: 0;
    margin: 0px;
    /* Optional: Customize the appearance */
    border: none; /* Remove any border */
    /* Add any other styles as needed */
}
.absent-btn {
    color: white;
    background-color: #f00;
    padding: 2px 6px;
    border-radius: 5px;
    position: relative;
}
.absent-btn > div {
    position: absolute;
    height: fit-content;
    width: max-content;
    background-color: #153c49;
    top: 0;
    border-radius: 5px;
    padding: 2px 6px;
    color: white;
    right: calc(100% + 8px);
    display: none;
}
.absent-btn > div::after {
    content: "";
    height: 0;
    width: 0;
    border: 5px solid;
    border-color: transparent transparent transparent #153c49;
    position: absolute;
    right: 0;
    transform: translate(100%, 5px);
}

.absent-btn:hover > div {
    display: block;
}
