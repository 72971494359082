.add-document-form-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: 10px;
    padding: 40px;
}
.add-document-form-container > div {
    width: 100%;
}
/* .add-document-form-container .documents-categories-container {
    width: 100%;
} */
