.SettingSection-container{
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid #EDEFF5;
}
.SettingSection-container .config-group {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 18px;
}
.SettingSection-container .config-options {
    display: flex;
    flex-direction: column;
    width: 70%;
}
.SettingSection-container .config-options .option-container .option-container-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 25px 5px;
    padding-bottom: 15px;
    padding-right: 15px;
}
.SettingSection-container .config-options .option-container .option-container-2 {
    padding: 5px;
}
.SettingSection-container .config-options .option-container:not(:last-child) {
    border-bottom: 1px solid #EDEFF5;
}