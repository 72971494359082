.contract-widget-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #edeff5;
    border-radius: 7px;
    padding: 5px 10px;
    margin: auto;
    max-width: 80%;
    min-width: 200px;
    margin-bottom: 10px;
    gap: 10px;
    min-height: 40px;
}

.contract-widget-container .widget-icon {
    display: flex;
    justify-content: center;
    width: 50px;
}

.contract-widget-container .widget-content {
    min-width: 80%;
}

.contract-widget-container .widget-content .top-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contract-widget-container .widget-download-icon {
    display: flex;
    justify-content: center;
    width: 70px;
    gap: 7px;
}