.modal-container {
    display: flex; /* Hidden by default */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed; /* Stay in place */
    z-index: 11; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}
.modal-container .modal-content {
    padding: 20px;
    animation: animate-open 0.4s ease-out;
}
/* Add Animation */
@keyframes animate-open {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}
@keyframes animate-close {
    from {
        top: 0;
        opacity: 1;
    }
    to {
        top: -300px;
        opacity: 0;
    }
}
