.configuration-row {
    background-color: white;
    box-shadow: 0px 10px 10px -12px rgba(0, 0, 0, 0.25);
}

.planning-configuration-options {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    height: -webkit-fill-available;
}
.planning-configuration-options .option-container {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px 4px;
    gap: 5px;
    box-shadow: inset 0 0 0 0.5px #edeff5;
    width: 100%;
}
.planning-configuration-options .option-container .option-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.add-note-btn-container .Button {
    opacity: 0.4;
    // transition: 0.2s ease;
}
.add-note-btn-container:hover > .Button {
    opacity: 1;
}
.add-note-btn-container:hover .delete-note-icon {
    opacity: 1;
}
.delete-note-icon {
    position: absolute;
    background-color: #f9e5e5;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border: 1px solid red;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}
.delete-note-icon > svg {
    display: block;
}
.first-note-item:hover > div {
    display: flex;
    // display: block;
}
.first-note-item > div {
    display: none;
    flex-direction: column;
    align-items: flex-start;
}
.first-note-item .hover-notes::before {
    content: "";
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: transparent transparent #c7cbe2 transparent;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}
.service-day-parent {
    width: 100%;
    height: 40px;
    display: flex;
}
.service-day-parent .service-day-child {
    display: flex;
    justify-content: center;
    align-items: center;
}
.service-day-parent .service-day-child:not(:last-child) {
    border-right: 1px solid #edeff5;
}
.serives-number {
    position: relative;
    font-family: "DM Sans-Bold", Helvetica;
    margin-right: 2px;
    border-radius: 3px;
    color: white;
    font-size: 70%;
    font-weight: 100;
    padding: 1px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}
.serives-number .steakers-show {
    position: absolute;
    top: -10px;
    z-index: 101;
    // background-color: rgb(237, 239, 245);

    background-color: white;
    // border: 1px solid rgb(199, 203, 226);
    border: 1px solid #2a346d6a;
    border-radius: 5px;
    padding: 10px;
    width: max-content;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.service-name:hover + .serives-number .steakers-show {
    display: block;
}
.serives-number:hover .steakers-show {
    display: block;
}
@media (min-width: 769px) and (max-width: 1200px) {
    .planning-configuration-options .option-container {
        background-color: aqua;
        padding: 10px 0;
    }
}
