.auth-header-container {
    display: flex;
    flex-direction: row;
    height: 8%;
    background-color: white;
    justify-content: center;
}

.auth-header-lang-container {
    position: absolute;
    width: 100%;
    height: 200px;
}
.auth-header-lang-container > div {
    position: absolute;
    right: 15px;
    top: 15%;
}
