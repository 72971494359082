.table-header-container {
    width: 100%;
    background-color: #ffffff;
}

.table-header-container .header-emp {
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-header-container .header-emp > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
}
.table-header-container .header-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    // background-color: #edeff5;
}
.table-header-container .header-date .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-header-container .header-date .date-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 250px;
}
.table-header-container .header-date .date-info .date-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 10px;
}

.table-header-container .header-date .view-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-subheader-container {
    background-color: #ffffff;
}

.day-hour-parent {
    width: 100%;
    display: flex;
    position: relative;
    border-top: 1px solid #edeff5;
    position: relative;
}
.day-hour-parent .time-range-line {
    height: 1px;
    background-color: #c7cbe2;
    position: absolute;
    top: -1px;
    transition: 0.3s ease;
    border-radius: 5px;
}
.day-hour-parent:hover .time-range-line {
    height: 3px;
}

.day-hour-parent .opening-time,
.day-hour-parent .closing-time {
    position: absolute;
    padding: 1.5px 3px;
    background-color: #c7cbe2;
    font-size: 10px;
    font-weight: 600;
    border-radius: 5px;
    color: #2e336c;
    transform: translateY(calc(-100% - 3px));
    opacity: 0;
    transition: 0.3s ease;
}
.day-hour-parent .closing-time {
    transform: translate(-100%, calc(-100% - 3px));
}

.day-hour-parent:hover .opening-time,
.day-hour-parent:hover .closing-time {
    opacity: 1;
}

.time-line-circle {
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    background-color: #f63;
    box-shadow: hsla(15, 100%, 60%, 0.5) 1.5px 0px 3px;
    transform: translateY(-50%);
    border-radius: 50%;
}
.time-line {
    position: absolute;
    top: 0;
    width: 2px;
    background-color: #f63;
    box-shadow: hsla(15, 100%, 60%, 0.5) 1.5px 0px 3px;
    transform: translateX(-50%);
    z-index: 0;
}

.day-hour-parent .day-hour-child {
    font-size: 14px;
    font-weight: 600;
    color: #2a346d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.day-hour-parent .day-hour-child:not(:last-child) {
    border-right: 1px solid #edeff5;
}
.day-hour-parent .day-hour-child .day-hour-lines {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}
.day-hour-parent .day-hour-child .day-hour-lines > div {
    width: calc(100% / 3);
    height: 5px;
}

.day-hour-parent .day-hour-child .day-hour-lines > div:not(:last-child) {
    border-right: 1px solid #edeff5;
}
.day-hour-parent .day-hour-child .day-hour-lines > div:nth-child(2) {
    height: 7px;
    border-color: #c7cbe2;
}
