.select-input-container {
    display: flex;
    flex-direction: column;
}
.select-input-container span {
    font-size: 12;
    font-weight: 400;
    color: #8e96c1;
}
.select-input-container select {
    padding: 7px;
    width: 200px;
    background-color: transparent;
    border: 1px solid #c7cbe2;
    border-radius: 4px;
    color: #4c5690;
    outline: none;
}
.select-input-container select option {
    color: #4c5690;
}

.select-input-container ._select-input {
}
.select-input-container ._select-input .custom-option-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.select-input-container ._select-input .custom-option-container img {
    margin-right: 10px;
}
.select-input-container ._select-input .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
