.big-image-container .image-stripe {
    position: absolute;
    height: 100px;
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0.293); */
    background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0),
        #0f556d95 10%,
        #0f556d95 90%,
        rgba(0, 0, 0, 0)
    );
    bottom: -100px;
    /* bottom: 0px; */
    left: 0;
    display: flex;

    justify-content: space-between;
    padding: 0 100px;
    align-items: center;
    transition: 0.3s ease-in-out;
    color: white;
    z-index: 1
    /* gap: 200px; */
}
.big-image-container:hover .image-stripe {
    bottom: 0;
}
.big-image-container .chevron-wrapper {
    transition: 0.3s ease-in-out;
    opacity: 0.5;
}
.big-image-container .chevron-wrapper:hover {
    opacity: 1;
}
.big-image-container .image-stripe .left-side {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}
.big-image-container .image-stripe .right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
