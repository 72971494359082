.vacation-page-container {
    display: flex;
}
// part 1
.vacation-page-container .vacation-history-container {
    display: flex;
    flex-direction: column;
    width: 75%;
    min-height: 50vh;
}
.vacation-history-container .vacation-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    height: 90px;
}
.vacation-filter-container .status-filter-container,
.date-filter-box .status-filter-container {
    display: flex;
}
.status-filter-container button {
    border-radius: 0;
    border: 0.5px solid #c7cbe2;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 400;
}
.status-filter-container button:first-child {
    border-radius: 4px 0 0 4px;
}
.status-filter-container button:last-child {
    border-radius: 0 4px 4px 0;
}
.vacation-filter-container .year-filter-container {
    display: flex;
}

.vacation-history-container .history-info-container {
    margin-right: 20px;
}
.history-info-container .history-item-container {
    display: flex;
    align-items: left;
    margin-bottom: 7px;
    border-radius: 8px;
    border: 1px solid #edeff5;
    background-color: #fff;
    padding: 14px 20px;
}
.history-item-container .history-created-date-container {
    display: flex;
    flex-direction: column;
}

.history-item-container .history-date-container {
    display: flex;
}
//part 2
.vacation-page-container .leave-balance-container {
    display: flex;
    justify-content: center;
    width: 25%;
    margin-top: 25px;
    height: max-content;
   

}
.leave-balance-container .leave-balance-box {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #edeff5;
    border-radius: 8px;
    min-width: 300px;
}
.leave-balance-box .leave-balance-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding: 13px 20px;
    padding-bottom: 0;
}
.leave-balance-box .leave-balance-content {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #edeff5;
    justify-content: space-evenly;
    margin-top: 18px;
}
.leave-balance-content .leave-balance-info-box {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    padding: 16px 20px;
    background-color: #fff;
    border-radius: 8px;
    min-width: 30%;
}
.leave-balance-content .leave-balance-info-box:not(:last-child) {
    border-bottom: 1px solid #edeff5;
}

// WorkHours Component

.work-hours-container {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.work-hours-container .header-work-hours {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.header-work-hours .date-filter-box {
    display: flex;
}
.header-work-hours .attendance-container {
}
.attendance-container .attendance-button {
    gap: 13px;
    padding: 4px 0px 4px 13px;
}
.attendance-container .attendance-export-container {
    position: absolute;
    width: 100%;
    background-color: #fff;
    top: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 14px 0px #2a346d33;
    padding: 13px 11px;
}

.attendance-export-container hr {
    margin: 12px 0;
}
.attendance-export-container .export-toogle-box {
    display: flex;
    gap: 5px;
}
.export-toogle-box .switch {
    width: 32px;
    height: 18px;
}
.export-toogle-box .switch input:checked + .slider:before {
    transform: translateX(14px);
}
.export-toogle-box .switch .slider:before {
    height: 13px;
    width: 12px;
    left: 4px;
    bottom: 2px;
}
.date-filter-box .status-filter-container {
}

.work-hours-container .body-work-hours {
}
.body-work-hours .work-hours-table {
    display: table;
    // table-layout: fixed;
    width: 100%;
    margin-top: 37px;
}
// Header Table
.work-hours-table .header-table-work-hours td {
    border: 1px solid #edeff5;
    border-right: none;
    border-left: none;
    padding: 13px 0;
}
.work-hours-table .header-table-work-hours td:first-child {
    padding: 13px 0 13px 20px;
    border-left: 1px solid #edeff5;
}
.work-hours-table .header-table-work-hours td:last-child {
    // padding: 13px 0 13px 20px;
    border-right: 1px solid #edeff5;
}

// Footer Table
.work-hours-table .footer-table-work-hours td {
    border: 1px solid #edeff5;
    border-right: none;
    border-left: none;
    padding: 13px 0;
}
.work-hours-table .footer-table-work-hours td:first-child {
    padding: 13px 0 13px 20px;
    border-left: 1px solid #edeff5;
}
.work-hours-table .footer-table-work-hours td:last-child {
    // padding: 13px 0 13px 20px;
    border-right: 1px solid #edeff5;
}

// Body Table
.work-hours-table .content-table-work-hours td {
    border: 1px solid #edeff5;
    border-right: none;
    border-left: none;
    padding: 13px 0;
}
.work-hours-table .content-table-work-hours td:first-child {
    padding: 13px 0 13px 20px;
    border-left: 1px solid #edeff5;
}
.work-hours-table .content-table-work-hours td:last-child {
    border-right: 1px solid #edeff5;
}

.content-table-work-hours .timing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 5px 0;
}
.content-table-work-hours .timing-container .colored-circle-container {
    margin: 5px;
}
.content-table-work-hours .timing-container .timing-decorator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
}
.swal2-actions {
    display: flex;
    flex-direction: row-reverse; /* Reverses the order of the buttons */
}
/*
.work-hours-table td {
    border: 1px solid #edeff5;
}
.work-hours-table td:first-child {
    padding: 13px 0 0 20px;
}
.work-hours-table td:last-child {
}
*/
