.vacation-page-container {
  display: flex;
}

.vacation-page-container .vacation-history-container {
  display: flex;
  flex-direction: column;
  width: 75%;
  min-height: 50vh;
}

.vacation-history-container .vacation-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  height: 90px;
}

.vacation-filter-container .status-filter-container,
.date-filter-box .status-filter-container {
  display: flex;
}

.status-filter-container button {
  border-radius: 0;
  border: 0.5px solid #c7cbe2;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
}

.status-filter-container button:first-child {
  border-radius: 4px 0 0 4px;
}

.status-filter-container button:last-child {
  border-radius: 0 4px 4px 0;
}

.vacation-filter-container .year-filter-container {
  display: flex;
}

.vacation-history-container .history-info-container {
  margin-right: 20px;
}

.history-info-container .history-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  border-radius: 8px;
  border: 1px solid #edeff5;
  background-color: #fff;
  padding: 14px 20px;
}

.history-item-container .history-date-container {
  display: flex;
}

.vacation-page-container .leave-balance-container {
  display: flex;
  justify-content: center;
  width: 25%;
  margin-top: 90px;
  height: max-content;
}

.leave-balance-container .leave-balance-box {
  display: flex;
  flex-direction: column;
  background-color: #edeff5;
  border-radius: 8px;
  min-width: 300px;
  padding: 13px 20px;
}

.leave-balance-box .leave-balance-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.leave-balance-box .leave-balance-content {
  display: flex;
  justify-content: space-evenly;
  gap: 9px;
  margin-top: 18px;
}

.leave-balance-content .leave-balance-info-box {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 8px;
  min-width: 30%;
}

.work-hours-container {
  display: flex;
  min-height: 60vh;
}

.work-hours-container .header-work-hours {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-work-hours .date-filter-box {
  display: flex;
}

.attendance-container .attendance-button {
  gap: 13px;
  padding: 12px 13px;
}

.attendance-container .attendance-export-container {
  position: absolute;
  width: 100%;
  background-color: #fff;
  top: 100%;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: "0px 4px 14px 0px #2a346d33";
}
