.planning-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.planning-table {
    border-radius: 12px;
    box-shadow: 1px 1px 4px #11106e1a;
    background-color: #fff;
    // height: 100%;
    width: 100%;
}
.planning-table th {
    outline: 1px solid #edeff5;
    padding: 0;
}

.planning-table th:not(.without-border-b) {
    border-bottom: 1px solid #edeff5;
}
.planning-table thead {
    position: sticky;
    top: 0;
    background-color: #f7f7f7;
}
.planning-table thead th {
    background-color: white;
}
.planning-table tbody th {
    // padding: 0 2px;
}
.fullscreen-btn {
    border-radius: 3px;
    padding: 2px;
    display: flex;
    justify-content: center;
    gap: 3px;
    align-items: center;
    background-color: #2a8bab;
    height: 26px;
    cursor: pointer;
    overflow: hidden;
}
.fullscreen-btn .fullscreen-icon {
    width: fit-content;
    padding: 0;
    margin-left: 2px;
}
.fullscreen-btn .fullscreen-title {
    max-width: 0;
    transition: 0.3s ease-in-out;
    position: static;
    overflow: hidden;
}
.fullscreen-btn .fullscreen-title div {
    // background-color: red;
    transform: translateX(-100%);
    width: max-content;
    color: white;
    font-size: 12px;
    margin-right: 2px;
    transition: 0.3s ease-in-out;
}
.fullscreen-btn:hover .fullscreen-title {
    position: relative;
    max-width: 200px;
}
.fullscreen-btn:hover .fullscreen-title div {
    transform: translateX(0);
}
