.worked-hours-table-container {
    overflow-x: auto;
    max-height: calc(100vh - 250px);
}
.attendance-button {
    gap: 13px;
    padding: 4px 0px 4px 13px;
}
.attendance-export-container {
    position: absolute;
    width: 100%;
    background-color: #fff;
    top: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 14px 0px #2a346d33;
    padding: 13px 11px;
}

.attendance-export-container hr {
    margin: 12px 0;
}
.attendance-export-container .export-toogle-box {
    display: flex;
    gap: 5px;
}
.export-toogle-box .switch {
    width: 32px;
    height: 18px;
}
.export-toogle-box .switch input:checked + .slider:before {
    transform: translateX(14px);
}
.export-toogle-box .switch .slider:before {
    height: 13px;
    width: 12px;
    left: 4px;
    bottom: 2px;
}
