#slide0-body-container,#slide4-body-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
#slide4-body-container .progressSteps-container{
    width: 40%;
}
#slide4-body-container .slide-info-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}