/* Define your breakpoints as variables */
:root {
    --breakpoint-mobile: 480px;
    --breakpoint-tablet: 768px;
    --breakpoint-desktop: 992px;
    --breakpoint-large-desktop: 1200px;
    --min-height-short: 600px;
}

/* Styles for screens smaller than the mobile breakpoint */
@media (max-width: var(--breakpoint-mobile)) {
    body {
        background-color: lightgray;
    }
}

/* Styles for screens taller than the short height and wider than mobile */
@media (min-width: var(--breakpoint-mobile)) and (min-height: var(--min-height-short)) {
    body {
        background-color: lightblue;
    }
}

/* Styles for screens between tablet and desktop breakpoints, considering height */
@media (min-width: var(--breakpoint-tablet)) and (max-width: var(--breakpoint-desktop)) {
    body {
        background-color: lightgreen;
    }
}

/* Styles for screens taller than the short height and wider than tablet */
@media (min-width: var(--breakpoint-tablet)) and (min-height: var(--min-height-short)) {
    body {
        background-color: lightyellow;
    }
}

/* Styles for screens equal to or larger than the large desktop breakpoint, considering height */
@media (min-width: var(--breakpoint-large-desktop)) {
    body {
        background-color: lightcoral;
    }
}
@media (max-width: 1300px) {
    .left-auth {
        font-size: 30px;
    }
    .right-auth {
    }
}
@media (max-width: 1100px) {
    .left-auth {
        font-size: 25px;
    }
    .right-auth {
    }
}
@media (max-width: 930px) {
    .left-auth {
        display: none;
        font-size: medium;
    }
    .right-auth {
        width: 100%;
        border-radius: 10px !important;
    }
}
