.popup-card-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 450px;
    border-radius: 20px;
}

.popup-card-container .popup-header {
    display: flex;
    justify-content: space-between;
    background-color: #edeff5;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 20px 20px 0 0;
}
.popup-card-container .popup-header h1 {
    margin-left: 50px;
}
.popup-card-container .popup-header svg {
    margin-right: 50px;
}

.popup-card-container .popup-content {
}

.popup-card-container .popup-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid #edeff5;
    border-radius: 0 0 20px 20px;
}
.popup-card-container .popup-footer button {
    margin-right: 10px;
}
