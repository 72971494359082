.employee-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 10px;
}
.employee-info-container .employee-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.employee-info-container .employee-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  width: 50%;
}

.employee-info-container .employee-info .employee-position {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: start;
  border-radius: 10px;
  border: 1px solid #c7cbe2;
  margin: auto;
  margin-left: 0;
  padding: 0 10px;
  padding-top: 2px;
}
.employee-info-container .employee-info .employee-position .dot {
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}
.employee-info-container .employee-more-info {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-end;
  width: 25%;
}
.employee-info-container .employee-more-info .employee-hours {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  padding-top: 2px;
  border-radius: 5px;
  background-color: #edeff5;
}
